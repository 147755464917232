import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { setSubMenu } from '../../../../../app/modules/auth';
import { KEY_LOCALSTORAGE } from '../../../../../app/modules/auth/core/_consts';
import "../../../../../app/modules/styles/index.scss";
import { localStorageItem } from '../../../../../app/modules/utils/LocalStorage';
import { KTSVG } from '../../../../helpers';
export function MenuInner() {  
  useEffect(() => {
    const tabList:any = document.querySelector('.header-list-nav');
    const isScroll = tabList.clientWidth < tabList?.scrollWidth;
    const rightArrow = document.querySelector('.header-list-nav-container .arrow-right');
    const leftArrow = document.querySelector('.header-list-nav-container .arrow-left');
    if(isScroll) {
      rightArrow?.classList?.remove("hidden");
      const handleShowIcon = () => {
        if(tabList) {
          if(tabList.scrollLeft >= 20) {
            leftArrow?.classList?.remove("hidden");
          } else {
            leftArrow?.classList?.add("hidden");
          }
  
          const maxScrollValue = tabList.scrollWidth - tabList.clientWidth - 20;        
          if(tabList.scrollLeft >= maxScrollValue) {
            rightArrow?.classList?.add("hidden");
          } else {
            rightArrow?.classList?.remove("hidden");
          }
        }
      }
      rightArrow?.addEventListener('click', () => {
        if(tabList) {
          tabList.scrollLeft += 100;
          handleShowIcon();
        }
      });
      leftArrow?.addEventListener('click', () => {
        if(tabList) {
          tabList.scrollLeft -= 100;
          handleShowIcon();
        }
      });
      tabList?.addEventListener('scroll', handleShowIcon);
    }
    setSubMenu();
  }, []);

  const logoData = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["logo"]
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className='header-brand'>
        <div className='header-brand-link w-100 flex flex-middle flex-center' >
          <Link to="#" className='white spaces fs-16 fw-700 flex flex-middle' > 
            {logoData && <img src={`data:image/svg+xml;base64,${logoData}`} className='logo' alt="" />}
              HỆ THỐNG QUẢN LÝ SỰ CỐ Y KHOA
          </Link>
        </div>
      </div>
      <div className="header-list-nav-container">
        <KTSVG path='/media/icons/arrow-left.svg' className="text-white arrow-left hidden" svgClassName={`position-absolute`}/>
        <div className="header-list-nav">
        </div>
        <KTSVG path='/media/icons/arrow-left.svg' className="text-white arrow-right hidden" svgClassName={`position-absolute`}/>
      </div>
    </div>
  );
}
