/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TSubMenu } from "../../../../../app/pages/Homepage/listMenu";
import { headerConstant } from "../../header/header-menus/constant";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { Button } from "react-bootstrap";
import { localStorageItem } from "../../../../../app/modules/utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../../../app/modules/auth/core/_consts";
import { useNavigate } from "react-router-dom";
import { useLayout } from "../../../core";
import { KTSVG } from "../../../../helpers";

const SidebarMenuMain = () => {
  const intl = useIntl();
  const listMenuSelect = localStorage.getItem(headerConstant?.LIST_SUB_MENU);

  const [listMenuItem, setListMenuItem] = useState<TSubMenu[]>([]);
  useEffect(() => {
    if(!listMenuSelect) return;
    setListMenuItem(JSON.parse(listMenuSelect));
  },[listMenuSelect])
  const { config } = useLayout()
  const { app } = config;

const menuPath = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["oauthClientUrl"]

  return (
    <>
      {
        listMenuItem?.map((menuItem: TSubMenu, index: number) => (
          <SidebarMenuItem
            key={index}
            to={menuItem.to}
            title={intl.formatMessage({ id: menuItem?.title })}
            hasBullet={menuItem.hasBullet}
            icon={menuItem.icon}
          />
        ))
      }
      <div className="menu-item ">
        <a href={menuPath} className="menu-link without-sub">
          {app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <KTSVG path={"/media/icons/menu.svg"} className='svg-icon-2' />
            </span>
          )}
          <span className='menu-title'>Menu App</span>
        </a>
      </div>
    </>
  );
};

export { SidebarMenuMain };

